import * as React from 'react';
import './video-block.component.scss';

interface VideoBlockFields {
  title: string;
  src: string;
  video?: ContentfulImage;
  config?: {
    controls: boolean;
    autoplay: boolean;
    loop: boolean;
  };
  className?: string;
  placeholderImage?: ContentfulImage;
}
export class VideoBlock extends React.Component<
  { fields: VideoBlockFields },
  { playing: boolean }
> {
  public video: HTMLVideoElement;
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }
  play() {
    this.setState({ playing: true });
    this.video.play();
  }
  setRef(ref: HTMLVideoElement) {
    this.video = ref;
  }
  render() {
    const { fields } = this.props;
    return (
      <div className={'video-block ' + fields.className}>
        {fields.src && (
          <iframe
            src={fields.src}
            frameBorder="0"
            allowFullScreen={true}
            title={fields.title}
          ></iframe>
        )}
        {fields.video && (
          <video
            poster={
              fields.placeholderImage
                ? 'https:' + fields.placeholderImage.file.url + '?q=70&fm=jpg'
                : ''
            }
            controls={fields.config.controls && this.state.playing}
            loop={fields.config.loop}
            muted={fields.config.autoplay}
            autoPlay={fields.config.autoplay}
            preload={fields.config.autoplay ? 'auto' : 'metadata'}
            ref={ref => this.setRef(ref)}
          >
            <source src={fields.video.file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {fields.video && !fields.config.autoplay && (
          <button
            className="play"
            id="play-button"
            onClick={() => this.play()}
            hidden={this.state.playing}
          >
            <img
              src={require('../../assets/icons/play.svg')}
              alt="Play video"
            />
          </button>
        )}
      </div>
    );
  }
}
