import * as React from 'react';
import { Image } from '../image/image.component';
import './image-block.component.scss';
interface ContentfulImage {
  file: { url: string };
}

interface ImageBlock {
  altText: string;
  className: string;
  image: ContentfulImage;
}

export function ImageBlock({
  fields,
  scale,
}: {
  fields: ImageBlock;
  scale: number;
}) {
  return (
    <section className={'image-block ' + fields.className}>
      <Image fields={fields.image} alt={fields.altText} scale={scale || 1} />
    </section>
  );
}
