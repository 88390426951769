import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import './showcase.component.scss';
import { cleanBody } from '../../core/helpers';
import { Image } from '../image/image.component';
import { Link, navigate } from 'gatsby';

interface ShowcaseBlockFields {
  body: ContentfulText;
  image?: ContentfulImage;
  config?: any;
  className?: string;
}

export function ShowcaseBlock({ fields }: { fields: ShowcaseBlockFields }) {
  const body = cleanBody(fields.body.body);
  return (
    <div
      className={'showcase-block ' + fields.className}
      onClick={() => navigate(fields.config.path)}
    >
      <Image fields={fields.image} alt={fields.config.shipName} scale={0.7} />
      <div
        className="slow"
        style={{
          backgroundImage:
            'url("https:' +
            fields.image.file.url +
            '?fm=jpg&fl=progressive&q=25&w=800")',
        }}
      ></div>
      <ReactMarkdown className="body" source={body} />
    </div>
  );
}
