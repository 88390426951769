import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import './quote.component.scss';
import { cleanBody } from '../../core/helpers';

interface QuoteBlockFields {
  body: ContentfulText;
  cite?: string;
  citeTitle?: string;
  className?: string;
}

export function QuoteBlock({ fields }: { fields: QuoteBlockFields }) {
  const body = cleanBody(fields.body.body);

  return (
    <section className={'quote-block ' + fields.className}>
      <blockquote>
        <ReactMarkdown source={body} />
      </blockquote>
      {fields.cite && <cite className="author">{fields.cite}</cite>}
      {fields.citeTitle && <span className="title">{fields.citeTitle}</span>}
    </section>
  );
}
