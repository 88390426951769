import * as React from 'react';
import { Blocks } from '../blocks/blocks';
import './container.component.scss';

export default ({ fields }) => {
  const items = fields.blocks.length;
  const containerClassName = `layout-${fields.layout} items-${items} ${fields.className}`;

  return (
    <Blocks
      containerClassName={containerClassName}
      blocks={fields.blocks}
      config={fields.config}
      title={fields.title}
      scale={1 / (items * 0.8)}
    />
  );
};
