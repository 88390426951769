import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import './markdown.component.scss';
import { cleanBody } from '../../core/helpers';

export function MarkdownBlock({ fields }) {
  const body = cleanBody(fields.body.body);
  return (
    <section className={'markdown-block ' + fields.className}>
      <ReactMarkdown source={body} />
    </section>
  );
}
