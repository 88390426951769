import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import './person.component.scss';
import { Image } from '../image/image.component';
import { Link } from 'gatsby';

interface PersonBlockFields {
  description?: ContentfulText;
  image: ContentfulImage;
  name: string;
  title?: string;
  email?: string;
  phone?: string;
}

export function PersonBlock({ fields }: { fields: PersonBlockFields }) {
  return (
    <div className={'person-block '}>
      <Image fields={fields.image} alt={fields.name} />
      {fields.description && (
        <div className="description">
          <p>{fields.description.description}</p>
        </div>
      )}
      <div className="info">
        {fields.title && <span className="title">{fields.title}</span>}
        <h4 className="name">{fields.name}</h4>
        {fields.phone && (
          <a className="phone" href={'tel:' + fields.phone}>
            {fields.phone}
          </a>
        )}
        {fields.email && (
          <a className="email" href={'mailto:' + fields.email}>
            {fields.email}
          </a>
        )}
      </div>
    </div>
  );
}
