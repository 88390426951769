import * as React from 'react';
import Container from '../container/container.component';
import { MarkdownBlock } from '../markdown/markdown.component';
import { ImageBlock } from '../image-block/image-block.component';
import { QuoteBlock } from '../quote/quote.component';
import { ShowcaseBlock } from '../showcase/showcase.component';
import { PersonBlock } from '../person/person.component';
import { BlogPostsContainer } from '../blog-posts-container/blog-posts-container.component';
import { ExpansionPanelBlock } from '../expansion-panel/expansion-panel.component';
import { LogosBlock } from '../logos-block/logos-block.component';
import { VideoBlock } from '../video-block/video-block.component';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export function Blocks({
  blocks,
  containerClassName,
  scale,
  config,
  title,
}: {
  blocks: any[];
  containerClassName?: string;
  scale?: number;
  title?: string;
  config?: { scrollCarousel: boolean; showTitle: boolean };
}) {
  if (!blocks) {
    return <h2>Something is wrong here!</h2>;
  }
  const child = (
    <div className={containerClassName}>
      {blocks.map((block, i) => {
        switch (block.internal.type) {
          case 'ContentfulContainer':
            return <Container key={i} fields={block} />;
          case 'ContentfulMarkdownBlock':
            return <MarkdownBlock key={i} fields={block} />;
          case 'ContentfulImageBlock':
            return <ImageBlock key={i} fields={block} scale={scale} />;
          case 'ContentfulQuoteBlock':
            return <QuoteBlock key={i} fields={block} />;
          case 'ContentfulShowcaseBlock':
            return <ShowcaseBlock key={i} fields={block} />;
          case 'ContentfulPersonBlock':
            return <PersonBlock key={i} fields={block} />;
          case 'ContentfulBlogPostsContainer':
            return <BlogPostsContainer key={i} fields={block} />;
          case 'ContentfulExpansionPanelBlock':
            return <ExpansionPanelBlock key={i} fields={block} />;
          case 'ContentfulLogosBlock':
            return <LogosBlock key={i} fields={block} />;
          case 'ContentfulVideoEmbeddedBlock':
            return <VideoBlock key={i} fields={block} />;

          default:
            return <p>{JSON.stringify(block)}</p>;
        }
      })}
    </div>
  );

  if (!config) {
    return child;
  }
  if (config.showTitle) {
    return (
      <div>
        <h3 className="container-title">{title}</h3>
        {child}
      </div>
    );
  }

  if (config.scrollCarousel) {
    return <ScrollContainer blocks={blocks} className={containerClassName} />;
  }

  return child;
}

export class ScrollContainer extends React.Component<
  {
    blocks: any[];
    className?: string;
  },
  {
    leftSide: boolean;
    rightSide: boolean;
  }
> {
  public element: HTMLElement;
  public parent: HTMLElement;
  private scrollSubscription: Subscription;
  constructor(props) {
    super(props);
    this.state = { leftSide: true, rightSide: false };
  }
  componentDidMount() {
    this.scrollSubscription = fromEvent(this.element, 'scroll')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.setState({ leftSide: this.element.scrollLeft < 50 });
        this.setState({
          rightSide:
            this.element.scrollWidth - 50 <=
            this.element.scrollLeft + this.parent.clientWidth,
        });
      });
  }
  componentWillUnmount() {
    this.scrollSubscription.unsubscribe();
  }
  move(dir: 'left' | 'right') {
    const value = dir === 'right' ? 0.5 : -0.5;
    this.element.scrollBy({
      left: window.innerWidth * value,
      behavior: 'smooth',
    });
  }
  setParent(ref: HTMLElement) {
    this.parent = ref;
  }
  setElement(ref: HTMLElement) {
    this.element = ref;
  }
  render() {
    return (
      <div className="scroll-carousel" ref={ref => this.setParent(ref)}>
        <button
          className={
            'scroll-button button-left ' + (this.state.leftSide && 'hidden')
          }
          onClick={() => this.move('left')}
        >
          <img
            src={require('../../assets/icons/carousel-arrow.svg')}
            alt="More on the left"
          />
        </button>
        <button
          className={
            'scroll-button button-right ' + (this.state.rightSide && 'hidden')
          }
          onClick={() => this.move('right')}
        >
          <img
            src={require('../../assets/icons/carousel-arrow.svg')}
            alt="More on the right"
          />
        </button>
        <div className={this.props.className} ref={ref => this.setElement(ref)}>
          {this.props.blocks.map((block, i) => {
            switch (block.internal.type) {
              case 'ContentfulShowcaseBlock':
                return <ShowcaseBlock key={i} fields={block} />;

              default:
                return (
                  <p>
                    ONLY ShowcaseBlock is supported for Scroll carousel! Got
                    child: {JSON.stringify(block)}
                  </p>
                );
            }
          })}
        </div>
      </div>
    );
  }
}
