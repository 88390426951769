import * as React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './logos-block.component.scss';

interface LogosBlockFields {
  logos?: ContentfulImage[];
  config?: any;
  className?: string;
}

export function LogosBlock({ fields }: { fields: LogosBlockFields }) {
  const perPage = 8;
  const containers = fields.logos.reduce((acc, image) => {
    if (!acc[0]) {
      acc[0] = [image];
      return acc;
    }

    for (let index = 0; index < fields.logos.length / perPage; index++) {
      if (!acc[index]) {
        acc[index] = [image];
        return acc;
      }
      if (acc[index].length < perPage) {
        acc[index].push(image);
        return acc;
      }
    }
  }, []);

  return (
    <div className={'logos-block ' + fields.className}>
      <Carousel>
        {containers.map((container, i) => (
          <Carousel.Item key={i}>
            <div className="logo-column-items">
              {container.map((image, i) => (
                <a
                  className="logo"
                  key={i}
                  href={image.description}
                  target="_blank"
                  rel="noopener"
                  title={image.description}
                >
                  <picture className="picture">
                    <source
                      className="lazy"
                      src-set={
                        'https:' + image.file.url + `?fm=webp&q=70&w=200`
                      }
                      type="image/webp"
                    />
                    <img
                      className="lazy"
                      data-src={'https:' + image.file.url + '?q=90'}
                      alt={image.description || 'partner logo'}
                    />
                  </picture>
                </a>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
