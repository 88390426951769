import * as React from 'react';
import './blog-posts-container.component.scss';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Image } from '../image/image.component';

interface BlogPostsContainerFields {
  title: string;
  config: {
    amount: number;
    loadMorePosts: boolean;
    highlight: boolean;
  };
}

export function BlogPostsContainer({
  fields,
}: {
  fields: BlogPostsContainerFields;
}) {
  const { loadMorePosts, amount, highlight } = fields.config;
  const [all, setAll] = React.useState(!!loadMorePosts ? false : true);
  const [currenAmount, addMore] = React.useState(!!amount ? amount : 90);

  const { allContentfulBlogPost } = useStaticQuery(graphql`
    query PostsQuery {
      allContentfulBlogPost(
        sort: { fields: publishedDate, order: DESC }
        filter: { publishedDate: { gt: "2" } }
      ) {
        nodes {
          title
          slug
          publishedDate
          publishedDateString: publishedDate(formatString: "MMM DD, YYYY")
          body {
            body
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const totalAmount = allContentfulBlogPost.nodes.length;

  function loadMore() {
    let newAmount = currenAmount + 9;
    if (newAmount >= totalAmount) {
      setAll(true);
      newAmount = totalAmount;
    }
    addMore(newAmount);
  }

  return (
    <section className="blog-posts-container">
      <div className="posts-container">
        {allContentfulBlogPost.nodes
          .filter((item, i) => {
            if (all) {
              return true;
            }
            return i < currenAmount;
          })
          .map((post: BlogPost, i) => {
            if (highlight && i === 0) {
              const readTime = Math.ceil(post.body.body.length / 4200);
              return (
                <Link
                  to={`/post/${post.slug}`}
                  className="post-highlight"
                  key={i}
                >
                  <Image fields={post.image} alt={post.title} />
                  <div className="info">
                    <h2 className="name">{post.title}</h2>
                    <span className="details">
                      {post.publishedDateString}{' '}
                      <span className="seperator"> | </span> {readTime}
                      min read
                    </span>
                  </div>
                  <div className="read-more">Read more</div>
                </Link>
              );
            }

            return (
              <Link to={`/post/${post.slug}`} className="post-card" key={i}>
                <img
                  className="picture"
                  src={'https:' + post.image.file.url + '?q=60&w=900&fm=jpg'}
                  alt={post.title}
                />
                {/* <span>{post.publishedDateString}</span> */}
                <div className="info">
                  <h4 className="name">{post.title}</h4>
                </div>
                <div className="read-more">Read more</div>
              </Link>
            );
          })}
      </div>
      {!all && (
        <button className="load-more" onClick={() => loadMore()}>
          Load more
        </button>
      )}
    </section>
  );
}
