export function cleanBody(body: string): string {
  /* Cleaning */

  // 1. Fix links if they are inner
  // 2. Remove comments
  // 3. Clean linebreaks
  return body
    .replace(/(http).+({link})|((<).+(>))/g, '')
    .replace(/(<br\/>)|(<br>)/g, '&nbsp;\n')
    .replace(/(\n\n(?!\|))/g, '\n&nbsp;\n');
}
