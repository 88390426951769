import * as React from 'react';
import './expansion-panel.component.scss';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { Image } from '../image/image.component';

interface ExpansionPanelBlockFields {
  config: {
    loadMore: boolean;
    startWith?: number;
    randomOrder?: boolean;
    left: string;
    right: string;
    items: {
      title: string;
      suffix?: string;
      description?: string;
      image?: string;
    }[];
  };
  className?: string;
}

export function ExpansionPanelBlock({
  fields,
}: {
  fields: ExpansionPanelBlockFields;
}) {
  const hours = new Date().getHours();
  const date = new Date().getDay();
  const minutes = new Date().getMinutes();
  const items = fields.config.items.sort((a, b) => {
    if (fields.config.randomOrder) {
      const theRandomFactor =
        a.title.length + hours - (b.title.length + date / 2);

      return theRandomFactor > 0 ? 1 : -1;
    }
    return 1;
  });
  const [showAll, load] = React.useState(
    !!fields.config.loadMore ? false : true,
  );

  return (
    <div className={'expansion-panel-block ' + fields.className}>
      <div className="panel-header">
        <span>{fields.config.left}</span>
        {fields.config.right && <span>{fields.config.right}</span>}
      </div>
      {items
        .filter((item, i) => {
          if (showAll) {
            return true;
          }
          return i < fields.config.startWith;
        })
        .map((item, i) => {
          return (
            <ExpansionPanel
              key={i}
              square={true}
              elevation={0}
              className="panel-row"
              style={{
                opacity: 0,
                animation: `fadein 300ms ${i * 25}ms 1 forwards`,
              }}
            >
              <ExpansionPanelSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={item.description ? '' : 'no-description'}
              >
                {item.title}
                {item.suffix && <span className="suffix">{item.suffix}</span>}
              </ExpansionPanelSummary>
              {item.description && (
                <ExpansionPanelDetails>
                  <p className="item-description">{item.description}</p>

                  {item.image && (
                    <Image
                      fields={{ file: { url: item.image } }}
                      alt={item.title}
                      scale={0.7}
                    />
                  )}
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          );
        })}
      {!showAll && (
        <button className="load-more" onClick={() => load(true)}>
          Load more
        </button>
      )}
    </div>
  );
}
